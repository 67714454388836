<template>
  <base-input
    :label="StringLanguage.username"
    :valid="isValid"
    :disabled="disabled"
    type="text"
    v-model="username"
    ref="baseinput"
    @keyup="$emit('keyup')"
    @keydown.space.prevent
    @input="handleInput"
    @focus="onFocus()"
    @blur="validate()"
    :design="`${!!design ? `${design}` : ''}`"
  />
</template>

<script>
import BaseInput from "@/components/Input";
import { baseEvents } from "@/mixins/baseEvents.js";
import User from "easyjs/lib/Schema/Account/User";

export default {
  mixins: [baseEvents],
  components: {
    BaseInput,
  },
  props: {
    required: {type: Boolean, default: true },
    disabled: {type: Boolean, default: false },
    design: String
  },
  mounted() {
    this.inputId = this.$refs.baseinput.id;
  },
  data() {
    return {
      username: this.value || "",
      isValid: true,
      inputId: "",
    };
  },
  methods: {
    validate() {
      if(!this.disabled){
        if ( this.username == null || this.username == "" || this.username.length < 5 || this.username.length > 20 ) {
          this.isValid = false;
          this.$parent.setErrorValidation(this.inputId, "6001");
          return;
        }

        const letters = this.username.match(/[a-z]/g);
        if (!letters || letters.length < 3) {
          this.isValid = false;
          this.$parent.setErrorValidation(this.inputId, "6002");
          return;
        }

        if (!this.username.match(/^[0-9a-z].+[0-9a-z]$/i)) {
          this.isValid = false;
          this.$parent.setErrorValidation(this.inputId, "6003");
          return;
        }

        if (!this.username.match(/^[0-9a-z\-_\.]+$/i)) {
          this.isValid = false;
          this.$parent.setErrorValidation(this.inputId, "6004");
          return;
        }
      }
    },
    onFocus() {
      this.isValid = true;
      this.$parent.removeErrorValidation(this.inputId);
    },
    handleInput(e) {
      this.username = this.username.toLowerCase();
      this.$emit("input", this.username);
    },
  },
};
</script>