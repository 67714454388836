<template>
  <form>
    <base-input
      type="password"
      autocomplete="off"
      v-model="password"
      ref="baseinputPassword"
      :label="label || StringLanguage.password"
      :valid="isPasswordValid"
      :maxlength="20"
      @input="handleInput('password')"
      @focus="onFocus('password')"
      @blur="validate()"
      :design="`${!!design ? `${design}` : ''}`"
    />
    <base-input
      type="password"
      autocomplete="off"
      v-model="confirmPassword"
      ref="baseinputConfirmPassword"
      :label="labelConfirm || StringLanguage.retypePassword"
      :valid="isConfirmPasswordValid"
      :maxlength="20"
      @input="handleInput('confirmPassword')"
      @focus="onFocus('confirmPassword')"
      @blur="validate()"
      :design="`${!!design ? `${design}` : ''}`"
    />
  </form>
</template>

<script>
import BaseInput from '@/components/Input';
import { baseEvents } from '@/mixins/baseEvents.js';

export default {
  mixins: [baseEvents],
  components: {
    BaseInput,
  },
  props: {
    required: { type: Boolean, default: true },
    label: { type: String, default: '' },
    labelConfirm: { type: String, default: '' },
    design: String,
  },
  mounted() {
    this.passwordInputId = this.$refs.baseinputPassword.id;
    this.confirmPasswordInputId = this.$refs.baseinputConfirmPassword.id;
  },
  data() {
    return {
      password: this.value.password || '',
      isPasswordValid: true,
      confirmPassword: this.value.confirmPassword || '',
      isConfirmPasswordValid: true,
      passwordInputId: '',
      confirmPasswordInputId: '',
    };
  },
  methods: {
    clearInputs() {
      this.password = '';
      this.confirmPassword = '';
    },
    validate() {
      if (
        this.$props.required ||
        this.password.length > 0 ||
        this.confirmPassword.length > 0
      ) {
        if (!this.password || this.password.length < 8) {
          this.isPasswordValid = false;
          this.$parent.setErrorValidation(this.passwordInputId, '5001');
          return;
        }

        if (this.isPasswordValid && this.confirmPassword !== this.password) {
          this.isConfirmPasswordValid = false;
          this.$parent.setErrorValidation(this.confirmPasswordInputId, '5002');
          return;
        }

        this.isPasswordValid = true;
        this.isConfirmPasswordValid = true;
      }
    },
    onFocus(key) {
      this.$parent.removeErrorValidation(this.confirmPasswordInputId);
      this.isConfirmPasswordValid = true;

      if (key === 'password') {
        this.$parent.removeErrorValidation(this.passwordInputId);
        this.isPasswordValid = true;
      }
    },
    handleInput(key) {
      this.$emit('input', this[key]);
    },
  },
};
</script>
