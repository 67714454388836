<template>
  <base-input
    type="password"
    v-model="password"
    ref="baseinput"
    :label="label || StringLanguage.password"
    :valid="isValid"
    :maxlength="20"
    @input="handleInput"
    @focus="onFocus()"
    @blur="validate()"
    :design="`${!!design ? `${design}` : ''}`"
  />
</template>

<script>
import BaseInput from "@/components/Input";
import { baseEvents } from "@/mixins/baseEvents.js";

export default {
  mixins: [baseEvents],
  components: {
    BaseInput,
  },
  props: {
    label: {type: String, default: '' },
    design: String
  },
  mounted() {
    this.inputId = this.$refs.baseinput.id;
  },
  data() {
    return {
      password: this.value || "",
      isValid: true,
      inputId: "",
    };
  },
  watch: {
    value: {
      deep: true,
      handler(newV) {
        this.password = newV;
      }
    },
  },
  methods: {
    validate() {
      if (this.password.length < 8) {
        this.isValid = false;
        this.$parent.setErrorValidation(this.inputId, "5001");
        return;
      }
    },
    onFocus() {
      this.isValid = true;
      this.$parent.removeErrorValidation(this.inputId);
    },
    handleInput(e) {
      this.$emit("input", this.password);
    },
  },
};
</script>