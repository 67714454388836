<template>
  <base-input
    type="text"
    v-model="name"
    ref="baseinput"
    :autocomplete="autocomplete"
    :label="label || StringLanguage.entireName"
    :valid="isValid"
    @input="handleInput"
    @focus="onFocus()"
    @blur="validate()"
    :design="`${!!design ? `${design}` : ''}`"
  />
</template>

<script>
import BaseInput from '@/components/Input';
import { baseEvents } from '@/mixins/baseEvents.js';

export default {
  mixins: [baseEvents],
  components: {
    BaseInput,
  },
  props: {
    required: { type: Boolean, default: true },
    autocomplete: { type: String, default: 'none' },
    label: { type: String, default: '' },
    design: String,
  },
  watch: {
    value: {
      deep: true,
      handler(newV) {
        this.name = newV;
      },
    },
  },
  mounted() {
    this.inputId = this.$refs.baseinput.id;
  },
  data() {
    return {
      name: this.value || '',
      isValid: true,
      inputId: '',
    };
  },
  methods: {
    validate() {
      if (this.$props.required || this.name) {
        this.isValid = this.name.length >= 2;
        if (!this.isValid) {
          this.$parent.setErrorValidation(this.inputId, '4001');
          return;
        }
      }
    },
    onFocus() {
      this.isValid = true;
      this.$parent.removeErrorValidation(this.inputId);
    },
    handleInput(e, event) {
      if (event.isTrusted) {
        // Obtem a posição do cursor no input
        let position = event.target.selectionStart;

        const alphaRegex = /[\d]/;

        // Não permite números no nome
        this.name = this.name.replace(alphaRegex, '');

        this.name = this.capitalize(this.name);
        this.$emit('input', this.name);

        // Atualiza a posição do cursor no input
        if (alphaRegex.test(event.data)) {
          position--;
        }

        this.$nextTick(() => {
          setTimeout(() => {
            event.target.setSelectionRange(position, position);
          }, 5);
        });
      }
    },
    capitalize(name) {
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
  },
};
</script>
