<template>
  <base-input
    type="text"
    v-model="email"
    ref="baseinput"
    :label="label || StringLanguage.email"
    :valid="isValid"
    :placeholder="placeholder"
    :autocomplete="autocomplete"
    @input="handleInput"
    @focus="onFocus()"
    @blur="validate()"
    :design="`${!!design ? `${design}` : ''}`"
    :disabled="disabled"
  />
</template>

<script>
import BaseInput from '@/components/Input';
import { baseEvents } from '@/mixins/baseEvents.js';

export default {
  mixins: [baseEvents],
  components: {
    BaseInput,
  },
  props: {
    required: { type: Boolean, default: false },
    autocomplete: { type: String, default: 'none' },
    placeholder: { type: String, default: '' },
    disabled: Boolean,
    design: String,
    label: { type: String, default: 'E-mail' },
  },
  watch: {
    value: {
      deep: true,
      handler(newV) {
        this.email = newV;
      },
    },
  },
  mounted() {
    this.inputId = this.$refs.baseinput.id;
  },
  data() {
    return {
      email: this.value || '',
      isValid: true,
      inputId: '',
    };
  },
  methods: {
    validate() {
      if (this.email) {
        const emailRegex =
          /^\w+([\.-]?\w+)+@\w+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/;

        this.isValid = emailRegex.test(this.email);
        if (!this.isValid) {
          this.$parent.setErrorValidation(this.inputId, '2000');
        }
      } else if (this.required) {
        this.isValid = false;
        this.$parent.setErrorValidation(this.inputId, '2002');
      }
    },
    onFocus() {
      this.isValid = true;
      this.$parent.removeErrorValidation(this.inputId);
    },
    handleInput(e) {
      this.email = this.email.toLowerCase().replace(/\s/g, '');
      this.$emit('input', this.email);
    },
  },
};
</script>
