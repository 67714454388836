<template>
  <base-input
    type="text"
    v-model="cellPhone"
    ref="baseinput"
    :label="StringLanguage.cellphone"
    :valid="isValid"
    :masked="true"
    :mask="['(##) ####-####', '(##) #####-####']"
    :maxlength="15"
    @input="handleInput"
    @focus="onFocus()"
    @blur="validate()"
    :design="`${!!design ? `${design}` : ''}`"
    :disabled="disabled"
  />
</template>

<script>
import BaseInput from "@/components/Input";
import { baseEvents } from "@/mixins/baseEvents.js";

export default {
  mixins: [baseEvents],
  components: {
    BaseInput,
  },
  props: {
    required: {type: Boolean, default: false },
    design: String,
    disabled: Boolean
  },
  mounted() {
    this.inputId = this.$refs.baseinput.id;
  },
  data() {
    return {
      cellPhone: this.value || "",
      isValid: true,
      inputId: "",
    };
  },
  methods: {
    validate() {
      if(this.cellPhone) {
        if (this.cellPhone.length !== 15) {
          this.isValid = false;
          this.$parent.setErrorValidation(this.inputId, "3002");
          return;
        }

        if (this.cellPhone[5] !== "9") {
          this.isValid = false;
          this.$parent.setErrorValidation(this.inputId, "3003");
          return;
        }
      } else if(this.required) {
          this.isValid = false;
          this.$parent.setErrorValidation(this.inputId, "3005");
      }
    },
    onFocus() {
      this.isValid = true;
      this.$parent.removeErrorValidation(this.inputId);
    },
    handleInput(e) {
      // Remove os caracteres especias da mask
      const phone = this.cellPhone.replace(/[\(\)\-\ ]*/g, "");

      this.$emit("input", phone);
    },
  },
  watch: {
    value(newValue) {
      this.cellPhone = newValue;
    }
  },
};
</script>
