import { render, staticRenderFns } from "./CellphoneInput.vue?vue&type=template&id=58722c1d&"
import script from "./CellphoneInput.vue?vue&type=script&lang=js&"
export * from "./CellphoneInput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports