<template>
  <base-input
    type="tel"
    v-model="birthday"
    ref="baseinput"
    :label="label || StringLanguage.birthday"
    :valid="isValid"
    :autocomplete="autocomplete"
    :mask="StringLanguage.dateMask"
    :masked="true"
    :maxlength="10"
    @input="handleInput"
    @focus="onFocus()"
    @blur="validate()"
    :design="`${!!design ? `${design}` : ''}`"
  />
</template>

<script>
import BaseInput from '@/components/Input';
import { baseEvents } from '@/mixins/baseEvents.js';

export default {
  mixins: [baseEvents],
  components: {
    BaseInput,
  },
  props: {
    required: { type: Boolean, default: false },
    autocomplete: { type: String, default: 'none' },
    label: { type: String, default: '' },
    design: String,
  },
  watch: {
    value: {
      deep: true,
      handler(newV) {
        this.birthday = newV;
      },
    },
  },
  mounted() {
    this.inputId = this.$refs.baseinput.id;
  },
  data() {
    return {
      birthday: this.value || '',
      isValid: true,
      inputId: '',
    };
  },
  methods: {
    validate() {
      if (this.birthday) {
        if (!this.birthday || this.birthday.length < 10) {
          this.isValid = false;
          this.$parent.setErrorValidation(this.inputId, '9000');
          return;
        }

        const [day, month, year] = this.birthday.split('/');

        const currentYear = new Date().getFullYear();
        if (+year < 1901 || +year > currentYear) {
          this.isValid = false;
          this.$parent.setErrorValidation(this.inputId, '9003');
          return;
        }

        if (+month < 1 || +month > 12) {
          this.isValid = false;
          this.$parent.setErrorValidation(this.inputId, '9002');
          return;
        }

        const daysByMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        if (+day < 1 || +day > daysByMonth[+month - 1]) {
          this.isValid = false;
          this.$parent.setErrorValidation(this.inputId, '9001');
          return;
        }
      } else if (this.required) {
        this.isValid = false;
        this.$parent.setErrorValidation(this.inputId, '9000');
      }
    },
    onFocus() {
      this.isValid = true;
      this.$parent.removeErrorValidation(this.inputId);
    },
    handleInput(_, event) {
      if (event.isTrusted) {
        // Obtem a posição do cursor no input
        let position = event.target.selectionStart;

        // Verifica se vai haver adição da máscara ao input
        if (StringLanguage.dateMask[position - 1] !== '#' && event.data) {
          position++;
        }

        this.$emit('input', this.birthday);

        // Atualiza a posição do cursor no input
        this.$nextTick(() => {
          setTimeout(() => {
            event.target.setSelectionRange(position, position);
          }, 5);
        });
      }
    },
  },
};
</script>
